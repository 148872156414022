import React from "react"
import ArticleContentBody from "../../components/pages/ArticleDetails/ArticleContentBody"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const SignUpWithIndividualOnboardingByUser = () => {
  return (
    <Layout>
      <Seo
        title="How to Sign Up with Individual Onboarding by User"
        description={`During the self sign-up process with individual onboarding, users receive clear instructions on attaining verification status after submitting all the required details via the Synkli App.`}
      />

      {/* This slug is matched with articles in Content */}
      <ArticleContentBody slug={`sign-up-with-individual-onboarding-by-user`} />
    </Layout>
  )
}

export default SignUpWithIndividualOnboardingByUser
